<template>
	<div class="CommodityMerchantEdit">
		<w-navTab titleText="店铺管理"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="title">店铺名称</div>
			<div class="header">
				<div class="header-item">
					<div class="item-left">店铺名称</div>
					<div class="item-right">
						<input type="text" v-model="name" placeholder="请输入店铺名称">
					</div>
				</div>
			</div>
			<div class="title">店铺大图</div>
			<div class="body">
				<div class="uploader">
					<div class="uploader-item" v-for="(vo, index) in banner">
						<img class="uploader-close" src="@/assets/img/uploader_icon_close.png"
							@click="deleteBanner(index)" />
						<img class="uploader-image" :src="vo" @click="showPopup(banner,index)" />
					</div>
					<div class="uploader-item uploader-item-select">
						<van-uploader :after-read="onSelectBanner" v-show="isShowBanner" upload-icon="plus"></van-uploader>
					</div>
				</div>
			</div>
			<div class="title">店铺头像</div>
			<div class="body">
				<div class="uploader">
					<div class="uploader-item" v-for="(vo, index) in picture">
						<img class="uploader-close" src="@/assets/img/uploader_icon_close.png"
							@click="deletePicture(index)" />
						<img class="uploader-image" :src="vo" @click="showPopup(picture,index)" />
					</div>
					<div class="uploader-item uploader-item-select">
						<van-uploader :after-read="onSelectPicture" v-show="isShowPicture" upload-icon="plus"></van-uploader>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-button" @click="onSubimt">
			<div class="button-text">保存</div>
		</div>
	</div>
</template>

<script>
	import UploadApi from '@/api/upload';
	import VantVendor from '@/vendor/vant';
	import WTitle from '@/components/Title';
	import merchant from '@/api/merchant';
	import ValidatorVendor from '@/vendor/validator';
	export default{
		name:'CommodityMerchantEdit',
		data() {
			return{
				id: '',
				name: '',
				banner: [],
				isShowBanner: true,
				picture: [],
				isShowPicture: true,
				loadingPage: true
			}
		},
		watch: {
			banner(newVal, oldVal) {
				this.isShowBanner = newVal.length < 4;
			},
			picture(newVal, oldVal) {
				this.isShowPicture = newVal.length < 1;
			}
		},
		created() {
			this.id = this.$route.params.id;
			this.getMerchantInfo()
		},
		methods:{
			showPopup(item, index) {
				VantVendor.ImagePreview({
					images: item,
					startPosition: index
				});
			},
			// 获取商家信息
			getMerchantInfo(){
				merchant.merchantIndex({merchant_id: this.id})
					.then(result=>{
						this.name = result.data.name
						if(result.data.banner == ''){
							this.banner = []
						}else{
							this.banner = result.data.banner
						}
						if(result.data.logo == null){
							this.picture = []
						}else{
							this.picture.push(result.data.logo)
						}
						this.loadingPage = false
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 轮播图
			onSelectBanner(selected) {
				VantVendor.Toast.loading('上传中');
				UploadApi.image(selected.file)
					.then(result => {
						this.banner.push(result.data.fileUrl);
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
					.finally(() => {
						VantVendor.Toast.clear();
					});
			},
			deleteBanner(index) {
				this.banner.splice(index, 1);
			},
			// 头像
			onSelectPicture(selected) {
				VantVendor.Toast.loading('上传中');
				UploadApi.image(selected.file)
					.then(result => {
						this.picture.push(result.data.fileUrl);
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
					.finally(() => {
						VantVendor.Toast.clear();
					});
			},
			deletePicture(index) {
				this.picture.splice(index, 1);
			},
			onSubimt(){
				if(this.name == ''){
					return VantVendor.Toast('店铺名不能为空');
				}
				if(this.banner.length == 0){
					return VantVendor.Toast('店铺顶图不能为空');
				}
				if(this.picture.length == 0){
					return VantVendor.Toast('店铺头像不能为空');
				}
				let params = {
					merchant_id: this.id,
					name: this.name,
					logo: this.picture.join(),
					banner: this.banner.join()
				}
				merchant.merchantUpdate(params)
					.then(result=>{
						VantVendor.Toast.success('保存成功')
						setTimeout(()=>{
							this.$router.go(-1)
						},1000)
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
		},
		components: {
			WTitle
		}
	}
</script>

<style scoped lang="less">
	.CommodityMerchantEdit{
		.container{
			padding: 0 10px;
			padding-bottom: 90px;
			box-sizing: border-box;
			
			.title{
				display: flex;
				align-items: center;
				font-weight: bold;
				font-size: 18px;
				line-height: 30px;
				color: #3377FF;
				margin: 15px 0 10px;
				box-sizing: border-box;
				&:before{
					content: '';
					display: block;
					width: 3px;
					height: 20px;
					margin-right: 5px;
					background: #3377FF;
					border-radius: 100px;
				}
			}
			
			.header{
				width: 100%;
				padding: 10px;
				background: #FFF;
				border-radius: 8px;
				box-sizing: border-box;
				.header-item{
					display: flex;
					justify-content: space-between;
					align-items: center;
					.item-left{
						font-size: 16px;
						line-height: 24px;
						color: #777;
					}
					.item-right{
						font-size: 16px;
						line-height: 24px;
						color: #222;
						input{
							border: 0;
							text-align: right;
						}
					}
				}
			}
			
			.body{
				padding: 10px 4px;
				background-color: #fff;
				box-sizing: border-box;
				border-radius: 6px;
				margin-top: 10px;
				.uploader {
					display: flex;
					flex-wrap: wrap;
					.uploader-item {
						position: relative;
						margin: 0 5px;
						
						.uploader-close {
							position: absolute;
							top: 5px;
							right: 5px;
							width: 16px;
							height: 16px;
						}
				
						.uploader-image {
							width: 75px;
							height: 75px;
							object-fit: cover;
							border-radius: 6px;
						}
					}
				
					.uploader-item-select {
						position: relative;
				
						/deep/ .van-uploader__upload {
							width: 75px;
							height: 75px;
							margin: 0;
							border-radius: 6px;
							background-color: #f2f2f2;
				
							.van-icon {
								font-size: 30px;
								color: #aaa;
							}
						}
					}
				}
			}
		}
		.footer-button {
			position: fixed;
			bottom: 20px;
			left: 0;
			padding: 0 10px;
			width: 100%;
			box-sizing: border-box;
		
			.button-text {
				padding: 10px 0;
				background: #3377FF;
				font-size: 18px;
				line-height: 26px;
				text-align: center;
				color: #FFF;
				border-radius: 100px;
				box-sizing: border-box;
			}
		}
	}	
</style>